var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "drawingFunctionStatistics" } }, [
    _c("div", { staticClass: "item-title" }, [
      _c("span", { staticClass: "title" }, [_vm._v("审图人员问题分布统计")]),
      _c("span", { staticClass: "text-num" }, [
        _vm._v("问题总数：" + _vm._s(_vm.totalQueNum))
      ]),
      _c("span", { staticClass: "text-num" }, [
        _vm._v("有效问题：" + _vm._s(_vm.totalValidNum))
      ]),
      _c("span", { staticClass: "text-num" }, [
        _vm._v("重复问题：" + _vm._s(_vm.totalRepeatNum))
      ]),
      _c("span", { staticClass: "text-num" }, [
        _vm._v("无效问题：" + _vm._s(_vm.totalInvalidNum))
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("label", [_vm._v("角色选择：")]),
          _c(
            "el-select",
            {
              ref: "funcsSelect",
              staticClass: "el-select--maia",
              attrs: {
                "popper-append-to-body": false,
                size: "small",
                multiple: "",
                "collapse-tags": "",
                placeholder: "角色"
              },
              on: { change: _vm.handleChangeFunction },
              model: {
                value: _vm.drawUsersQuery.functionNameList,
                callback: function($$v) {
                  _vm.$set(_vm.drawUsersQuery, "functionNameList", $$v)
                },
                expression: "drawUsersQuery.functionNameList"
              }
            },
            [
              _c("el-option", { attrs: { label: "全部角色", value: "" } }),
              _vm._l(_vm.newFunctionDicList, function(item) {
                return _c("el-option", {
                  key: item.roleTagId,
                  attrs: { label: item.roleTagName, value: item.roleTagName }
                })
              })
            ],
            2
          ),
          _c("label", [_vm._v("排序方式：")]),
          _c(
            "el-select",
            {
              ref: "userSortTypeSelect",
              staticClass: "el-select--maia",
              attrs: {
                "popper-append-to-body": false,
                size: "small",
                multiple: "",
                "collapse-tags": "",
                placeholder: "排序方式"
              },
              on: { change: _vm.handleChangeSortType },
              model: {
                value: _vm.drawUsersQuery.sortTypes,
                callback: function($$v) {
                  _vm.$set(_vm.drawUsersQuery, "sortTypes", $$v)
                },
                expression: "drawUsersQuery.sortTypes"
              }
            },
            _vm._l(_vm.queValidTypesData, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          !_vm.levelExcelType
            ? _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("导出Excel")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "info", disabled: "" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("Excel生成中...")]
              )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "item-chart",
        attrs: {
          "element-loading-text": "数据加载中，请稍后...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 1)"
        }
      },
      [
        _c("div", { staticClass: "chart", attrs: { id: "drawUsersChart" } }),
        _vm.functions.length === 0 || _vm.functions == null
          ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }