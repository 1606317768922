var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: { id: "prjDwgQuestion" }
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "search-box" },
        [
          !_vm.levelExcelType
            ? _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("导出Excel")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "info", disabled: "" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("Excel生成中...")]
              )
        ],
        1
      ),
      _c("div", { staticClass: "item-list" }, [
        _c(
          "div",
          { staticClass: "main-table" },
          [
            _c(
              "el-table",
              {
                staticClass: "is-grey",
                staticStyle: { width: "100%" },
                attrs: {
                  stripe: "",
                  height: "300",
                  size: "medium",
                  data: _vm.dwgQuestionsData
                }
              },
              [
                _c("el-table-column", {
                  attrs: { width: "60", label: "ID" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var $index = ref.$index
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "tableColor",
                              class: "tableColor" + $index
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.currentPageIndex *
                                    _vm.dwgQuestionsQuery.pageSize +
                                    $index +
                                    1
                                )
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "350",
                    "show-overflow-tooltip": "",
                    prop: "fileName",
                    label: "图纸名称"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "specName", label: "图纸专业" }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "problemNum",
                    label: "问题数量",
                    sortable: ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "peopleNum",
                    label: "审图人数",
                    sortable: ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "checkTime",
                    label: "审图时长",
                    sortable: ""
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "page-handler" },
              [
                _c("el-pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dwgQuestionsQuery.totalCount > 0,
                      expression: "dwgQuestionsQuery.totalCount > 0"
                    }
                  ],
                  attrs: {
                    background: true,
                    "current-page": _vm.dwgQuestionsQuery.pageNo,
                    "page-size": _vm.dwgQuestionsQuery.pageSize,
                    layout: _vm.pageLayout,
                    total: _vm.dwgQuestionsQuery.totalCount
                  },
                  on: { "current-change": _vm.getDwgQuestionsDataProxy }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-title" }, [
      _c("span", { staticClass: "title" }, [_vm._v("图纸问题分布")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }