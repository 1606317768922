var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog--maia",
      attrs: {
        title: "问题统计",
        visible: _vm.modalShow,
        "custom-class": "Statistical",
        "append-to-body": "",
        width: "1200px",
        top: "6.5vh",
        "before-close": _vm.modalClose
      },
      on: {
        "update:visible": function($event) {
          _vm.modalShow = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "dialogTitle" }, [
          _c(
            "div",
            [
              _c("span", [_vm._v(" 问题统计 ")]),
              _vm.isNewList && _vm.flag_plm && _vm.plmBoolean
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "maiaPlain",
                        size: "small",
                        disabled: _vm.turndwgResultShow
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.upPLM.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("确认上传节点反馈")]
                  )
                : _vm.isNewList && _vm.flag_plm && !_vm.plmBoolean
                ? _c(
                    "el-button",
                    { attrs: { type: "info", size: "small", disabled: "" } },
                    [_vm._v("正在上传，请稍后...")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "exportBtn" },
            [
              !_vm.reprotingFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "maiaPlain", size: "small" },
                      on: { click: _vm.generateReport }
                    },
                    [_vm._v("导出PPT")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "info", size: "small", disabled: "" } },
                    [_vm._v("PPT生成中...")]
                  ),
              !_vm.reprotingExcelFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "maiaPrimary", size: "small" },
                      on: { click: _vm.exportExcel }
                    },
                    [_vm._v("导出Excel")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "info", size: "small", disabled: "" } },
                    [_vm._v("Excel生成中...")]
                  )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "maia-content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24, id: "viewBox" } }, [
                _c(
                  "div",
                  { staticClass: "progressT" },
                  [
                    _c("el-progress", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.msgType,
                          expression: "msgType"
                        }
                      ],
                      staticStyle: { width: "96%", "margin-left": "5%" },
                      attrs: { percentage: _vm.msgprogress }
                    })
                  ],
                  1
                ),
                _vm.prjData.buId != "C2" &&
                _vm.prjData.buId != "C3" &&
                _vm.prjData.buId != null
                  ? _c(
                      "div",
                      { staticClass: "itemFord total" },
                      _vm._l(_vm.itemNameList, function(item, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "plane" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./icon/icon_" +
                                  (index + 1) +
                                  ".png"),
                                alt: ""
                              }
                            }),
                            _c("span", { staticClass: "info" }, [
                              index === 0
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.countList.staffDrawingTime || 0
                                        )
                                      )
                                    ]),
                                    _vm._v("小时")
                                  ])
                                : index === 1
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.countList.userNum || 0))
                                    ]),
                                    _vm._v("人次")
                                  ])
                                : index === 2
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.countList.validQuestionsNum || 0
                                        )
                                      )
                                    ]),
                                    _vm._v("例")
                                  ])
                                : index === 3
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.countList.threeStarsNum || 0)
                                      )
                                    ]),
                                    _vm._v("例")
                                  ])
                                : index === 4
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !!_vm.countList.modificationRate
                                            ? _vm.countList.modificationRate
                                            : "0%"
                                        )
                                      )
                                    ])
                                  ])
                                : index === 5
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !!_vm.countList.reviewRate
                                            ? _vm.countList.reviewRate
                                            : "0%"
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _c("p", [_vm._v(_vm._s(item))])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.prjData.buId == "C2" ||
                _vm.prjData.buId == "C3" ||
                _vm.prjData.buId == null
                  ? _c(
                      "div",
                      { staticClass: "itemFord total" },
                      _vm._l(_vm.itemNameListC2, function(item, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "plane" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./icon/icon_" +
                                  (index + 1) +
                                  ".png"),
                                alt: ""
                              }
                            }),
                            _c("span", { staticClass: "info" }, [
                              index === 0
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.countList.staffDrawingTime || 0
                                        )
                                      )
                                    ]),
                                    _vm._v("小时")
                                  ])
                                : index === 1
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.countList.userNum || 0))
                                    ]),
                                    _vm._v("人次")
                                  ])
                                : index === 2
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.countList.validQuestionsNum || 0
                                        )
                                      )
                                    ]),
                                    _vm._v("例")
                                  ])
                                : index === 3
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !!_vm.countList.modificationRate
                                            ? _vm.countList.modificationRate
                                            : "0%"
                                        )
                                      )
                                    ])
                                  ])
                                : index === 4
                                ? _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !!_vm.countList.reviewRate
                                            ? _vm.countList.reviewRate
                                            : "0%"
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _c("p", [_vm._v(_vm._s(item))])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "charts-cont" }, [
                  _c("div", { staticClass: "item_charts leftC bottomc" }, [
                    _c(
                      "div",
                      {
                        ref: "funcDistributionChartWrapper",
                        staticClass: "item",
                        staticStyle: { border: "0" }
                      },
                      [
                        _vm._t("default", function() {
                          return [
                            _c("div", { staticClass: "head" }, [
                              _c("span", [_vm._v("审图人员角色分布")])
                            ]),
                            _c("echarts-panel", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.issueFuncLoading,
                                  expression: "issueFuncLoading"
                                }
                              ],
                              ref: "funcDistributionChart",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "element-loading-text": "数据加载中，请稍后...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 1)",
                                id: "funcDistributionChart",
                                itemClass: "itemClass5",
                                orailData: _vm.drawingFuncsData
                              }
                            }),
                            _vm.funcsNums.length == 0 || _vm.funcsNums == null
                              ? _c("div", { staticClass: "empty" }, [
                                  _vm._v("暂无数据")
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "item_charts bottomc" }, [
                    _c(
                      "div",
                      {
                        ref: "issueTypeDistributionChartWrapper",
                        staticClass: "item",
                        staticStyle: { border: "0" }
                      },
                      [
                        _vm._t("default", function() {
                          return [
                            _c("div", { staticClass: "head" }, [
                              _c("span", [_vm._v("问题类型分布")])
                            ]),
                            _c("echarts-panel", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.issueTypesLoading,
                                  expression: "issueTypesLoading"
                                }
                              ],
                              ref: "issueTypeDistributionChart",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "element-loading-text": "数据加载中，请稍后...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 1)",
                                id: "issueTypeDistributionChart",
                                itemClass: "itemClass5",
                                orailData: _vm.issueTypesData
                              }
                            }),
                            _vm.issueTypes.length == 0 || _vm.issueTypes == null
                              ? _c("div", { staticClass: "empty" }, [
                                  _vm._v("暂无数据")
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "item_charts leftC" }, [
                    _c(
                      "div",
                      {
                        ref: "issueSpecDistributionChartWrapper",
                        staticClass: "item",
                        staticStyle: { border: "0" }
                      },
                      [
                        _vm._t("default", function() {
                          return [
                            _c("div", { staticClass: "head" }, [
                              _c("span", [_vm._v("问题专业分布")])
                            ]),
                            _c("echarts-panel", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.issueSpecsLoading,
                                  expression: "issueSpecsLoading"
                                }
                              ],
                              ref: "issueSpecDistributionChart",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "element-loading-text": "数据加载中，请稍后...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 1)",
                                id: "issueSpecDistributionChart",
                                itemClass: "itemClass5",
                                orailData: _vm.issueSpecsData
                              }
                            }),
                            _vm.issueSpecs.length == 0 || _vm.issueSpecs == null
                              ? _c("div", { staticClass: "empty" }, [
                                  _vm._v("暂无数据")
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "item_charts" }, [
                    _c(
                      "div",
                      {
                        ref: "issueResolveDataWrapper",
                        staticClass: "item",
                        staticStyle: { border: "0" }
                      },
                      [
                        _vm._t("default", function() {
                          return [
                            _c("div", { staticClass: "head" }, [
                              _c("span", [_vm._v("问题销项情况")])
                            ]),
                            _c("div", { staticClass: "resolve-cont" }, [
                              _c("div", { staticClass: "h-info" }, [
                                _c("span", [
                                  _vm._v("无效问题："),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.issueResolveData.cancelQuestion
                                      ) + "例"
                                    )
                                  ])
                                ]),
                                _c("span", [
                                  _vm._v("重复问题："),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.issueResolveData.repeatedQuestion
                                      ) + "例"
                                    )
                                  ])
                                ]),
                                _c("span", [
                                  _vm._v("删除问题："),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.issueResolveData.deleteQuestion
                                      ) + "例"
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.issueResolveLoading,
                                      expression: "issueResolveLoading"
                                    }
                                  ],
                                  staticClass: "progress-info",
                                  attrs: {
                                    "element-loading-text":
                                      "数据加载中，请稍后...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 1)"
                                  }
                                },
                                [
                                  !isNaN(_vm.issueResolveData.waitQuestionRate)
                                    ? _c(
                                        "div",
                                        { staticClass: "bar-item" },
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("待解决")
                                          ]),
                                          _c("el-progress", {
                                            staticClass: "item-bar",
                                            attrs: {
                                              color: "#F1657D",
                                              percentage:
                                                _vm.issueResolveData
                                                  .waitQuestionRate,
                                              "stroke-width": 20
                                            }
                                          }),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.issueResolveData
                                                  .waitQuestion
                                              ) + "例"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !isNaN(_vm.issueResolveData.modificationRate)
                                    ? _c(
                                        "div",
                                        { staticClass: "bar-item" },
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("已解决")
                                          ]),
                                          _c("el-progress", {
                                            staticClass: "item-bar",
                                            attrs: {
                                              color: "#19D2D1",
                                              percentage:
                                                _vm.issueResolveData
                                                  .modificationRate,
                                              "stroke-width": 20
                                            }
                                          }),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.issueResolveData
                                                  .editQuestion
                                              ) +
                                                "例/" +
                                                _vm._s(
                                                  _vm.issueResolveData
                                                    .modificationRate
                                                ) +
                                                "%"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !isNaN(_vm.issueResolveData.reviewRate)
                                    ? _c(
                                        "div",
                                        { staticClass: "bar-item" },
                                        [
                                          _c("span", { staticClass: "title" }, [
                                            _vm._v("已复核")
                                          ]),
                                          _c("el-progress", {
                                            staticClass: "item-bar",
                                            attrs: {
                                              color: "#56CA77",
                                              percentage:
                                                _vm.issueResolveData.reviewRate,
                                              "stroke-width": 20
                                            }
                                          }),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.issueResolveData
                                                  .checkQuestion
                                              ) +
                                                "例/" +
                                                _vm._s(
                                                  _vm.issueResolveData
                                                    .reviewRate
                                                ) +
                                                "%"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "charts-item" },
                  [
                    _c("drawing-time-statistics", {
                      ref: "funcTime",
                      attrs: {
                        isNewList: _vm.isNewList,
                        prjName: _vm.prjData.prjName,
                        listQuery: _vm.listQuery
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "charts-item" },
                  [
                    _c("drawing-function-statistics", {
                      ref: "funcBox",
                      attrs: {
                        isNewList: _vm.isNewList,
                        listQuery: _vm.listQuery
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "charts-item" },
                  [
                    _c("drawing-users-statistics", {
                      ref: "drawUserBox",
                      attrs: {
                        isNewList: _vm.isNewList,
                        listQuery: _vm.listQuery
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-cont" },
                  [
                    _c("div", { staticClass: "head" }, [
                      _c("span", [_vm._v("项目人员参审统计")])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "search-box",
                        staticStyle: { margin: "10px 0" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "input",
                          staticStyle: { width: "350px" },
                          attrs: {
                            placeholder: "请输入姓名",
                            "prefix-icon": "el-icon-search"
                          },
                          model: {
                            value: _vm.keyWord,
                            callback: function($$v) {
                              _vm.keyWord = $$v
                            },
                            expression: "keyWord"
                          }
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleDrawingUserSearch }
                          },
                          [_vm._v("查询")]
                        ),
                        !_vm.levelExcelType
                          ? _c(
                              "el-button",
                              {
                                staticClass: "excelBtn",
                                attrs: { type: "primary" },
                                on: { click: _vm.handleExcelBtn }
                              },
                              [_vm._v("导出Excel")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "excelBtn",
                                attrs: { type: "info", disabled: "" },
                                on: { click: _vm.handleExcelBtn }
                              },
                              [_vm._v("Excel生成中...")]
                            )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "data-table" },
                      [
                        _c(
                          "el-col",
                          { staticClass: "left-item", attrs: { span: 18 } },
                          [
                            _c(
                              "el-table",
                              {
                                staticClass: "is-grey",
                                attrs: {
                                  data: _vm.drawingUsersData,
                                  width: "100%",
                                  size: "medium",
                                  "cell-style": _vm.setTableTd
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "", width: "50" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "tableColor",
                                              class: "tableColor" + scope.$index
                                            },
                                            [_vm._v(_vm._s(scope.$index + 1))]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "姓 名",
                                    align: "center",
                                    prop: "userName"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "roleName",
                                    label: "角 色",
                                    align: "center",
                                    "show-overflow-tooltip": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.roleInfoList != null
                                            ? _c(
                                                "span",
                                                _vm._l(
                                                  _vm.whiteFilter(
                                                    scope.row.roleInfoList
                                                  ),
                                                  function(name, index) {
                                                    return _c(
                                                      "span",
                                                      { key: index },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              name.roleName
                                                            )
                                                          )
                                                        ]),
                                                        index <
                                                        _vm.whiteFilter(
                                                          scope.row.roleInfoList
                                                        ).length -
                                                          1
                                                          ? _c("span", [
                                                              _vm._v(";")
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "角色职能",
                                    align: "center",
                                    prop: "func",
                                    "show-overflow-tooltip": ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "block",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                                "white-space": "nowrap"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      scope.row.functionName
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.functionName
                                                    )
                                                  )
                                                ]
                                              ),
                                              scope.row.roleInfoList != null &&
                                              scope.row.roleInfoList.length > 0
                                                ? _c(
                                                    "span",
                                                    _vm._l(
                                                      scope.row.roleInfoList,
                                                      function(name, index) {
                                                        return _c(
                                                          "span",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    name.roleTagName
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    name.roleTagName
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            index <
                                                            scope.row
                                                              .roleInfoList
                                                              .length -
                                                              1
                                                              ? _c("span", [
                                                                  _vm._v(";")
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "审图时长(h)",
                                    align: "center",
                                    prop: "drawingTime",
                                    sortable: ""
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "总问题数",
                                    align: "center",
                                    prop: "totalQuestionNum",
                                    sortable: ""
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "有效问题数",
                                    align: "center",
                                    prop: "validQuestionNum",
                                    sortable: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "right-item", attrs: { span: 6 } },
                          [
                            _c(
                              "div",
                              { staticClass: "count-box" },
                              [
                                _c("el-row", { staticClass: "header" }),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("图纸总数量：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dwgTotalNumber.totalFileNum
                                          ) + "张"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("有问题图纸：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dwgTotalNumber.questionFileNum
                                          ) + "张"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("零问题图纸：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dwgTotalNumber.noQuestionFileNum
                                          ) + "张"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("有效问题总数：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.drawingIssueNumber.totalQsNum
                                          ) + "例"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("参审人员：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.drawingIssueNumber.internalUsers
                                          ) + "人次"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { span: 14 }
                                      },
                                      [_vm._v("综合人均有效问题数量：")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "text-left",
                                        attrs: { span: 10 }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.drawingIssueNumber.totalAvgQsNum
                                          ) + "例/人"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.tableTotalCount > _vm.pageSize &&
                    _vm.pageNo != _vm.tableTotalPage
                      ? _c(
                          "div",
                          { staticClass: "load-more" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "btn",
                                attrs: { plain: "" },
                                on: { click: _vm.loadMore }
                              },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("点击查看更多")
                                ]),
                                _c("br"),
                                _c("i", {
                                  staticClass: "el-icon-d-arrow-right",
                                  staticStyle: {
                                    transform: "rotate(90deg)",
                                    "-webkit-transform": "rotate(90deg)",
                                    "-moz-transform": "rotate(90deg)"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "charts-item" },
                  [
                    _c("dwg-question-statistics", {
                      attrs: {
                        isNewList: _vm.isNewList,
                        listQuery: _vm.listQuery
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "el-dialog--maia",
              attrs: {
                "custom-class": "plmPrompt",
                title: "提示",
                visible: _vm.plmVisible,
                "append-to-body": "",
                width: "400px",
                "before-close": _vm.plmClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.plmVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "prompt" }, [
                _vm._v("您好，确认已完成审图交底及问题回复了吗？")
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.plmClose } },
                    [_vm._v("点击前往交底及回复页面>>")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.plmClose } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.surePlm }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }