var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "projectDrawingTime" } }, [
    _c("div", { staticClass: "item-title" }, [
      _c("span", { staticClass: "title", staticStyle: { width: "auto" } }, [
        _vm._v(_vm._s(_vm.prjName) + "——审图人员及在线时长统计")
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("label", [_vm._v("角色选择：")]),
          _c(
            "el-select",
            {
              ref: "funcsSelect",
              staticClass: "el-select--maia",
              attrs: {
                "popper-append-to-body": false,
                size: "small",
                multiple: "",
                "collapse-tags": "",
                placeholder: "角色"
              },
              on: { change: _vm.handleChangeFunction },
              model: {
                value: _vm.prjDrawDurationQuery.functionNameList,
                callback: function($$v) {
                  _vm.$set(_vm.prjDrawDurationQuery, "functionNameList", $$v)
                },
                expression: "prjDrawDurationQuery.functionNameList"
              }
            },
            [
              _c("el-option", { attrs: { label: "全部角色", value: "" } }),
              _vm._l(_vm.newFunctionDicList, function(item) {
                return _c("el-option", {
                  key: item.roleTagId,
                  attrs: { label: item.roleTagName, value: item.roleTagName }
                })
              })
            ],
            2
          ),
          !_vm.levelExcelType
            ? _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("导出Excel")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "excelBtn",
                  attrs: { type: "info", disabled: "" },
                  on: { click: _vm.handleExcelBtn }
                },
                [_vm._v("Excel生成中...")]
              )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "item-chart",
        attrs: {
          "element-loading-text": "数据加载中，请稍后...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 1)"
        }
      },
      [
        _c("div", {
          staticClass: "chart",
          staticStyle: { height: "100%" },
          attrs: { id: "prjDrawTimeChartA" }
        }),
        _vm.prjDrawingTimeList.length === 0 || _vm.prjDrawingTimeList == null
          ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }